import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-space-around"
}
const _hoisted_2 = {
  key: 1,
  class: "chart-container"
}
const _hoisted_3 = {
  key: 0,
  class: "timeframe-indicator"
}

import { watch } from "vue";
import { ISeriesApi, LineSeriesPartialOptions } from "lightweight-charts";
import { useChart } from "@/composables/useChart";
import { ref } from "vue";
import moment from "moment";
import { useQuery } from "@tanstack/vue-query";
import { HistoricalService } from "@/api/generated";


export default /*@__PURE__*/_defineComponent({
  __name: 'TokenLineChart',
  props: {
  saleAddress: {
    type: String,
    required: true,
  },
  height: {
    type: Number,
    default: 200,
  },
},
  setup(__props) {

const props = __props;
const loading = ref(true);
const lineSeries = ref<ISeriesApi<"Line">>();

const { data } = useQuery({
  queryFn: () =>
    HistoricalService.getForPreview({ address: props.saleAddress }),
  queryKey: ["HistoricalService.getForPreview", props.saleAddress],
});

const { chartContainer, chart } = useChart({
  height: props.height,
  chartOptions: {
    grid: {
      horzLines: {
        visible: false,
      },
      vertLines: {
        visible: false,
      },
    },
    timeScale: {
      visible: false,
    },
    crosshair: {
      vertLine: {
        visible: false,
      },
      horzLine: {
        visible: false,
      },
    },
  },
  onChartReady: (chart) => {
    const seriesOptions: LineSeriesPartialOptions = {
      priceLineVisible: false,
      color: "rgb(244, 193, 12)",
      lineWidth: 2,
      crosshairMarkerVisible: false,
    };

    lineSeries.value = chart.addLineSeries(seriesOptions);
    lineSeries.value?.priceScale().applyOptions({
      visible: false, // disables auto scaling based on visible content
      ticksVisible: false,
    });
  },
});

watch(data, (newData) => {
  lineSeries.value?.setData([]);

  if (!newData?.result?.length) {
    return;
  }

  const formattedData = newData.result
    .map((item) => {
      return {
        time: moment(item.end_time).unix(),
        value: Number(item.last_price),
      };
    })
    .sort((a, b) => a.time - b.time);

  lineSeries.value?.setData(formattedData);
  chart.value?.timeScale().fitContent();
});

return (_ctx: any,_cache: any) => {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!

  return (!loading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_skeleton_loader, {
          type: "ossein",
          width: "140",
          height: "80"
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", {
          ref_key: "chartContainer",
          ref: chartContainer,
          class: "lw-chart"
        }, null, 512),
        (_unref(data)?.timeframe)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(data).timeframe), 1))
          : _createCommentVNode("", true)
      ]))
}
}

})