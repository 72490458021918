import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "bg-green text-copied"
}

import { useAeScan } from "@/composables/useAeScan";
import { formatAddress } from "@/utils/address";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressChip',
  props: {
    address: {},
    hideAvatar: { type: Boolean },
    link: { type: Boolean },
    copyable: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { prepareUrlForHash } = useAeScan();

const textCopied = ref(false);

function onChipClick() {
  if (props.copyable) {
    navigator.clipboard.writeText(props.address);
    textCopied.value = true;

    setTimeout(() => {
      textCopied.value = false;
    }, 1000);
  } else if (props.link) {
    window.open(prepareUrlForHash(props.address), "_blank");
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_ctx.address)
    ? (_openBlock(), _createBlock(_component_v_chip, {
        key: 0,
        size: "small",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (onChipClick()), ["prevent"]))
      }, {
        default: _withCtx(() => [
          (!_ctx.hideAvatar)
            ? (_openBlock(), _createBlock(_component_v_avatar, {
                key: 0,
                start: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    src: `https://avatars.z52da5wt.xyz/${_ctx.address}`
                  }, null, 8, ["src"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_unref(formatAddress)(_ctx.address)) + " ", 1),
          (_ctx.link)
            ? (_openBlock(), _createBlock(_component_v_avatar, {
                key: 1,
                end: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("mdi-open-in-new")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.copyable)
            ? (_openBlock(), _createBlock(_component_v_avatar, {
                key: 2,
                end: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("mdi-content-copy")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (textCopied.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Copied!"))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})