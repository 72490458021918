import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

import { useNetwork } from "@/composables/useNetwork";
import { NETWORKS } from "@/utils/constants";
import { useDisplay } from "vuetify/lib/framework.mjs";

export default /*@__PURE__*/_defineComponent({
  __name: 'ActiveNetwork',
  setup(__props) {

const { activeNetwork, changeActiveNetwork } = useNetwork();
const { smAndDown } = useDisplay();

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, null, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_chip, _normalizeProps(_guardReactiveProps(props)), {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            color: "green",
            class: "mr-2",
            size: "14"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("mdi-circle")
            ])),
            _: 1
          }),
          (!_unref(smAndDown))
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(activeNetwork).name), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("mdi-chevron-down")
            ])),
            _: 1
          })
        ]),
        _: 2
      }, 1040)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(NETWORKS), (network) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: network.networkId
            }, [
              (network.networkId !== _unref(activeNetwork).networkId)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    value: network.networkId,
                    onClick: ($event: any) => (_unref(changeActiveNetwork)(network.networkId))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(network.name), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["value", "onClick"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})