<template>
  <div v-if="activeAccount" class="d-flex align-center">
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-chip v-bind="props" class="pa-0">
          <div
            class="d-flex align-center px-4"
            style="height: 30px; background-color: #3f3f3f"
          >
            <v-avatar start loading>
              <v-progress-circular
                v-if="scanningForAccounts"
                indeterminate
                color="primary"
              />
              <v-img
                v-else
                :src="`https://avatars.z52da5wt.xyz/${activeAccount}`"
              />
            </v-avatar>

            {{ smAndDown ? "" : formatAddress(activeAccount) }}
          </div>
          <div
            class="d-flex align-center px-2"
            style="height: 30px; background-color: #2F2F2"
          >
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6">
                <path
                  d="M1.5 14C1.0875 14 0.73437 13.8531 0.44062 13.5594C0.14687 13.2656 0 12.9125 0 12.5V1.5C0 1.0875 0.14687 0.73438 0.44062 0.44063C0.73437 0.14688 1.0875 0 1.5 0H12.5C12.9125 0 13.2656 0.14688 13.5594 0.44063C13.8531 0.73438 14 1.0875 14 1.5V3.54167H12.5V1.5H1.5V12.5H12.5V10.4375H14V12.5C14 12.9125 13.8531 13.2656 13.5594 13.5594C13.2656 13.8531 12.9125 14 12.5 14H1.5ZM7.5 11C7.0875 11 6.73438 10.8531 6.44063 10.5594C6.14688 10.2656 6 9.9125 6 9.5V4.5C6 4.0875 6.14688 3.73438 6.44063 3.44063C6.73438 3.14688 7.0875 3 7.5 3H13.5C13.9125 3 14.2656 3.14688 14.5594 3.44063C14.8531 3.73438 15 4.0875 15 4.5V9.5C15 9.9125 14.8531 10.2656 14.5594 10.5594C14.2656 10.8531 13.9125 11 13.5 11H7.5ZM13.5 9.5V4.5H7.5V9.5H13.5ZM10.5 8.25C10.8472 8.25 11.1424 8.1285 11.3854 7.8854C11.6285 7.6424 11.75 7.3472 11.75 7C11.75 6.65278 11.6285 6.35764 11.3854 6.11458C11.1424 5.87153 10.8472 5.75 10.5 5.75C10.1528 5.75 9.8576 5.87153 9.6146 6.11458C9.3715 6.35764 9.25 6.65278 9.25 7C9.25 7.3472 9.3715 7.6424 9.6146 7.8854C9.8576 8.1285 10.1528 8.25 10.5 8.25Z"
                  fill="white"
                />
              </g>
            </svg>

            <strong class="px-2 font-weight-bolder">
              {{ smAndDown ? balance?.shorten() : balance?.prettify() }} AE
            </strong>
            <v-icon>mdi-chevron-down</v-icon>
          </div>
        </v-chip>
      </template>
      <v-list>
        <v-list-item :value="activeAccount">
          <template v-slot:prepend>
            <v-avatar start size="24">
              <v-img :src="`https://avatars.z52da5wt.xyz/${activeAccount}`" />
            </v-avatar>
          </template>
          <v-list-item-title>
            {{ formatAddress(activeAccount) }}
          </v-list-item-title>
        </v-list-item>
        <template v-for="address in accounts" :key="address">
          <v-list-item
            v-if="activeAccount !== address"
            :value="address"
            @click="setActiveAccount(address)"
          >
            <template v-slot:prepend>
              <v-avatar start size="24">
                <v-img :src="`https://avatars.z52da5wt.xyz/${address}`" />
              </v-avatar>
            </template>
            <v-list-item-title>
              {{ formatAddress(address) }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item @click="disconnectWallet()">
          <v-list-item-title class="text-red"> Disconnect </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="accountDetailDialog" max-width="600px">
      <v-card>
        <v-card-title>
          {{ formatAddress(activeAccount) }}
        </v-card-title>
        <v-card-text>
          <div><strong>Wallet:</strong> {{ walletInfo?.name }}</div>

          <div>
            <strong>Network ID:</strong>
            {{ walletInfo?.networkId }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="disconnectWallet()"> Disconnect </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import { useAccounts } from "@/stores/accounts";
import { useWalletConnect } from "@/composables/useWalletConnect";
import { formatAddress } from "@/utils/address";
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useAeppSdk } from "@/composables/aeppSdk";
import { Decimal } from "@/libs/decimal";
const { smAndDown } = useDisplay();

const { setActiveAccount } = useAccounts();
const { activeAccount, accounts } = storeToRefs(useAccounts());

const { disconnectWallet, walletInfo, scanningForAccounts } =
  useWalletConnect();

const { getAeSdk } = useAeppSdk();

const accountDetailDialog = ref(false);
const balance = ref(Decimal.ZERO);
const timer = ref();

async function loadBalance() {
  const sdk = await getAeSdk();
  if (sdk && activeAccount.value) {
    const loadedBalance = await sdk.getBalance(activeAccount.value);
    balance.value = Decimal.fromBigNumberString(loadedBalance);
  } else {
    balance.value = Decimal.ZERO;
  }
}
onMounted(() => {
  timer.value = setInterval(() => {
    loadBalance();
  }, 5000);
});

onUnmounted(() => {
  clearInterval(timer.value);
});
</script>
