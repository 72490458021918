import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { useDisplay } from "vuetify/lib/framework.mjs";

export default /*@__PURE__*/_defineComponent({
  __name: 'BottomNavigation',
  setup(__props) {

const { smAndDown } = useDisplay();

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_bottom_navigation = _resolveComponent("v-bottom-navigation")!

  return (_unref(smAndDown))
    ? (_openBlock(), _createBlock(_component_v_bottom_navigation, {
        key: 0,
        id: "bottom-navigation",
        grow: "",
        fixed: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            to: { name: 'home' },
            exact: "",
            color: "primary"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("span", null, "Tokens", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            to: { name: 'create-token' },
            exact: "",
            color: "primary"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("span", null, "Create Token", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            to: { name: 'about' },
            exact: "",
            color: "primary"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("span", null, "More", -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})