import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container d-flex align-center ga-2" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "d-flex align-center ga-4 px-2" }

import HeaderAccountCard from "@/components/Account/HeaderAccountCard.vue";
import WalletConnect from "@/components/WalletConnect/WalletConnect.vue";
import ToggleDarkMode from "@/components/Header/ToggleDarkMode.vue";
import ActiveNetwork from "@/components/Header/ActiveNetwork.vue";
import HeaderCurrencySelector from "@/components/Header/HeaderCurrencySelector.vue";
import AppLogo from "@/components/Common/AppLogo.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  setup(__props) {

const { smAndDown } = useDisplay();

const router = useRouter();

function onLogoClick() {
  router.push("/");
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    app: "",
    fixed: "",
    location: "top",
    class: "ga-2"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "logo-btn",
            variant: "plain",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (onLogoClick()))
          }, [
            _createVNode(AppLogo, {
              sm: _unref(smAndDown),
              style: {"height":"35px"}
            }, null, 8, ["sm"])
          ]),
          (!_unref(smAndDown))
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                class: "ml-6",
                color: "primary",
                to: "/",
                exact: ""
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Token List")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_unref(smAndDown))
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 1,
                color: "primary",
                to: "/create-token"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Create new token")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_unref(smAndDown))
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 2,
                color: "primary",
                to: "/invite",
                exact: ""
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Invite")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_v_spacer),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(HeaderCurrencySelector),
          _createVNode(ToggleDarkMode),
          _createVNode(ActiveNetwork),
          _createVNode(WalletConnect),
          _createVNode(HeaderAccountCard)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})