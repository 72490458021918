import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "rank" }
const _hoisted_3 = { class: "token-name" }


import { useDisplay } from "vuetify/lib/framework.mjs";
import PriceDataFormatter from "@/components/Common/Pricing/PriceDataFormatter.vue";
import TokenLineChart from "@/components/Charts/TokenLineChart.vue";
import AddressChip from "@/components/Common/AddressChip.vue";
import { TokenDto } from "@/api/generated";
import { useLiveTokenData } from "@/composables/useLiveTokenData";

export default /*@__PURE__*/_defineComponent({
  __name: 'TokenSaleCard',
  props: {
    token: {}
  },
  setup(__props: any) {

const { mdAndDown } = useDisplay();

const props = __props;
const { tokenData } = useLiveTokenData({
  token: props.token,
});

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.token?.sale_address)
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        block: "",
        flat: "",
        class: "px-2 token-card",
        to: `/sale/${_ctx.token.sale_address}`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { align: "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "6",
                md: "5",
                lg: "3",
                xl: "4",
                class: "px-6 py-8"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _cache[0] || (_cache[0] = _createTextVNode(" #")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.token.rank), 1)
                    ]),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.token.symbol), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "6",
                sm: "3",
                md: "2",
                lg: "2",
                class: "py-0 text-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(PriceDataFormatter, {
                    "price-data": _unref(tokenData).price_data
                  }, null, 8, ["price-data"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "6",
                sm: "3",
                md: "2",
                lg: "2",
                class: "py-0 token-market-cap text-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(PriceDataFormatter, {
                    bignumber: "",
                    "price-data": _unref(tokenData).market_cap_data
                  }, null, 8, ["price-data"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "3",
                md: "2",
                lg: "2",
                xl: "1",
                class: "px-0 text-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(AddressChip, {
                    address: _ctx.token.sale_address,
                    link: ""
                  }, null, 8, ["address"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "9",
                md: "1"
              }, {
                default: _withCtx(() => [
                  _createVNode(TokenLineChart, {
                    "sale-address": _ctx.token.address,
                    "chart-type": "line",
                    height: 60
                  }, null, 8, ["sale-address"])
                ]),
                _: 1
              }),
              (!_unref(mdAndDown))
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: "12",
                    sm: "3",
                    md: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        variant: "outlined",
                        block: "",
                        to: `/sale/${_ctx.token.sale_address}`
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode(" VIEW TOKEN ")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"]))
    : _createCommentVNode("", true)
}
}

})