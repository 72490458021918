import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex align-center justify-center",
  style: {"height":"100vh","width":"100vw"}
}
const _hoisted_2 = { class: "text-center" }

import AppHeader from "@/components/Header/AppHeader.vue";
import BottomNavigation from "@/components/BottomNavigation.vue";
import CollectInvitationLinkCard from "@/components/Invitation/CollectInvitationLinkCard.vue";
import CountDown from "@/components/CountDown.vue";

import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useAeppSdk } from "./composables/aeppSdk";
import { useWalletConnect } from "./composables/useWalletConnect";
import WebSocketClient from "./libs/WebSocketClient";
import { useAppStore } from "./stores/app";
import { useNetworkStore } from "./stores/network";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useTokenSaleFactory } from "./composables/useTokenSaleFactory";
import { useCurrencies } from "./composables/useCurrencies";
import { OpenAPI } from "./api/generated";
import { SHOW_COUNTDOWN } from "@/config";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { darkMode } = storeToRefs(useAppStore());
const { smAndDown } = useDisplay();
const loadingApp = ref(true);
const { initSdk, staticAeSdk, getSdk } = useAeppSdk();
const { activeNetwork } = storeToRefs(useNetworkStore());
const { checkWalletConnection } = useWalletConnect();
const { loadAeternityData, loadCurrencyRates } = useCurrencies();
const { initTokenSaleFactory } = useTokenSaleFactory();

watch(
  activeNetwork,
  async (network, prevNetwork) => {
    if (SHOW_COUNTDOWN) {
      return;
    }
    OpenAPI.BASE = `${network.apiUrl}`;
    try {
      await loadAeternityData();
      await loadCurrencyRates();
    } catch (e) {
      console.error("Error loading data", e);
    }
    try {
      await initSdk();
      const sdk = await getSdk();
      sdk.selectNode(network.name);
      staticAeSdk.selectNode(network.name);
      checkWalletConnection();
      initTokenSaleFactory();
    } catch (e) {
      //
    }
    loadingApp.value = false;
    if (network?.websocketUrl !== prevNetwork?.websocketUrl) {
      console.log("connecting WS to::", network);
      WebSocketClient.disconnect();
      WebSocketClient.connect(network.websocketUrl);
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_unref(SHOW_COUNTDOWN))
    ? (_openBlock(), _createBlock(CountDown, { key: 0 }))
    : (loadingApp.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_v_progress_circular, {
              indeterminate: "",
              color: "primary",
              size: "80"
            })
          ])
        ]))
      : (_openBlock(), _createBlock(_component_v_app, {
          key: 2,
          theme: _unref(darkMode) ? 'darkTokaen' : 'tokaen'
        }, {
          default: _withCtx(() => [
            _createVNode(AppHeader),
            _createVNode(_component_v_main, {
              class: _normalizeClass({
        'px-4 py-0': !_unref(smAndDown),
        'px-3 py-0': _unref(smAndDown),
      }),
              style: _normalizeStyle([{"margin-bottom":"56px","width":"100%"}, { 'margin-top': '80px' }])
            }, {
              default: _withCtx(() => [
                _createVNode(CollectInvitationLinkCard),
                _createVNode(_component_router_view)
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(BottomNavigation)
          ]),
          _: 1
        }, 8, ["theme"]))
}
}

})