import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_2 = {
  class: "d-flex align-center px-4",
  style: {"height":"30px","background-color":"#3f3f3f"}
}
const _hoisted_3 = {
  class: "d-flex align-center px-2",
  style: {"height":"30px","background-color":"#2F2F2"}
}
const _hoisted_4 = { class: "px-2 font-weight-bolder" }

import { useAccounts } from "@/stores/accounts";
import { useWalletConnect } from "@/composables/useWalletConnect";
import { formatAddress } from "@/utils/address";
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useAeppSdk } from "@/composables/aeppSdk";
import { Decimal } from "@/libs/decimal";

export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderAccountCard',
  setup(__props) {

const { smAndDown } = useDisplay();

const { setActiveAccount } = useAccounts();
const { activeAccount, accounts } = storeToRefs(useAccounts());

const { disconnectWallet, walletInfo, scanningForAccounts } =
  useWalletConnect();

const { getAeSdk } = useAeppSdk();

const accountDetailDialog = ref(false);
const balance = ref(Decimal.ZERO);
const timer = ref();

async function loadBalance() {
  const sdk = await getAeSdk();
  if (sdk && activeAccount.value) {
    const loadedBalance = await sdk.getBalance(activeAccount.value);
    balance.value = Decimal.fromBigNumberString(loadedBalance);
  } else {
    balance.value = Decimal.ZERO;
  }
}
onMounted(() => {
  timer.value = setInterval(() => {
    loadBalance();
  }, 5000);
});

onUnmounted(() => {
  clearInterval(timer.value);
});

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_unref(activeAccount))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_menu, null, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_chip, _mergeProps(props, { class: "pa-0" }), {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_avatar, {
                    start: "",
                    loading: ""
                  }, {
                    default: _withCtx(() => [
                      (_unref(scanningForAccounts))
                        ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                            key: 0,
                            indeterminate: "",
                            color: "primary"
                          }))
                        : (_openBlock(), _createBlock(_component_v_img, {
                            key: 1,
                            src: `https://avatars.z52da5wt.xyz/${_unref(activeAccount)}`
                          }, null, 8, ["src"]))
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_unref(smAndDown) ? "" : _unref(formatAddress)(_unref(activeAccount))), 1)
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _cache[4] || (_cache[4] = _createElementVNode("svg", {
                    width: "15",
                    height: "14",
                    viewBox: "0 0 15 14",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }, [
                    _createElementVNode("g", { opacity: "0.6" }, [
                      _createElementVNode("path", {
                        d: "M1.5 14C1.0875 14 0.73437 13.8531 0.44062 13.5594C0.14687 13.2656 0 12.9125 0 12.5V1.5C0 1.0875 0.14687 0.73438 0.44062 0.44063C0.73437 0.14688 1.0875 0 1.5 0H12.5C12.9125 0 13.2656 0.14688 13.5594 0.44063C13.8531 0.73438 14 1.0875 14 1.5V3.54167H12.5V1.5H1.5V12.5H12.5V10.4375H14V12.5C14 12.9125 13.8531 13.2656 13.5594 13.5594C13.2656 13.8531 12.9125 14 12.5 14H1.5ZM7.5 11C7.0875 11 6.73438 10.8531 6.44063 10.5594C6.14688 10.2656 6 9.9125 6 9.5V4.5C6 4.0875 6.14688 3.73438 6.44063 3.44063C6.73438 3.14688 7.0875 3 7.5 3H13.5C13.9125 3 14.2656 3.14688 14.5594 3.44063C14.8531 3.73438 15 4.0875 15 4.5V9.5C15 9.9125 14.8531 10.2656 14.5594 10.5594C14.2656 10.8531 13.9125 11 13.5 11H7.5ZM13.5 9.5V4.5H7.5V9.5H13.5ZM10.5 8.25C10.8472 8.25 11.1424 8.1285 11.3854 7.8854C11.6285 7.6424 11.75 7.3472 11.75 7C11.75 6.65278 11.6285 6.35764 11.3854 6.11458C11.1424 5.87153 10.8472 5.75 10.5 5.75C10.1528 5.75 9.8576 5.87153 9.6146 6.11458C9.3715 6.35764 9.25 6.65278 9.25 7C9.25 7.3472 9.3715 7.6424 9.6146 7.8854C9.8576 8.1285 10.1528 8.25 10.5 8.25Z",
                        fill: "white"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("strong", _hoisted_4, _toDisplayString(_unref(smAndDown) ? balance.value?.shorten() : balance.value?.prettify()) + " AE ", 1),
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("mdi-chevron-down")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 2
            }, 1040)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item, { value: _unref(activeAccount) }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_v_avatar, {
                      start: "",
                      size: "24"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_img, {
                          src: `https://avatars.z52da5wt.xyz/${_unref(activeAccount)}`
                        }, null, 8, ["src"])
                      ]),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(formatAddress)(_unref(activeAccount))), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(accounts), (address) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: address }, [
                    (_unref(activeAccount) !== address)
                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                          key: 0,
                          value: address,
                          onClick: ($event: any) => (_unref(setActiveAccount)(address))
                        }, {
                          prepend: _withCtx(() => [
                            _createVNode(_component_v_avatar, {
                              start: "",
                              size: "24"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_img, {
                                  src: `https://avatars.z52da5wt.xyz/${address}`
                                }, null, 8, ["src"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(formatAddress)(address)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["value", "onClick"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128)),
                _createVNode(_component_v_list_item, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(disconnectWallet)()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, { class: "text-red" }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" Disconnect ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_dialog, {
          modelValue: accountDetailDialog.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((accountDetailDialog).value = $event)),
          "max-width": "600px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(formatAddress)(_unref(activeAccount))), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Wallet:", -1)),
                      _createTextVNode(" " + _toDisplayString(_unref(walletInfo)?.name), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Network ID:", -1)),
                      _createTextVNode(" " + _toDisplayString(_unref(walletInfo)?.networkId), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      color: "red",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(disconnectWallet)()))
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Disconnect ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : _createCommentVNode("", true)
}
}

})