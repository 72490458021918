<template>
  <v-chip v-if="address" size="small" @click.prevent="onChipClick()">
    <v-avatar v-if="!hideAvatar" start>
      <v-img :src="`https://avatars.z52da5wt.xyz/${address}`"></v-img>
    </v-avatar>
    {{ formatAddress(address) }}
    <v-avatar v-if="link" end>
      <v-icon>mdi-open-in-new</v-icon>
    </v-avatar>
    <v-avatar v-if="copyable" end>
      <v-icon>mdi-content-copy</v-icon>
    </v-avatar>
    <div v-if="textCopied" class="bg-green text-copied">Copied!</div>
  </v-chip>
</template>

<script lang="ts" setup>
import { useAeScan } from "@/composables/useAeScan";
import { formatAddress } from "@/utils/address";
import { defineProps, ref } from "vue";

const props = defineProps<{
  address: string;
  hideAvatar?: boolean;
  link?: boolean;
  copyable?: boolean;
}>();

const { prepareUrlForHash } = useAeScan();

const textCopied = ref(false);

function onChipClick() {
  if (props.copyable) {
    navigator.clipboard.writeText(props.address);
    textCopied.value = true;

    setTimeout(() => {
      textCopied.value = false;
    }, 1000);
  } else if (props.link) {
    window.open(prepareUrlForHash(props.address), "_blank");
  }
}
</script>

<style>
.text-copied {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 700;
}
</style>
