import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, unref as _unref, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center justify-center"
}
const _hoisted_2 = { class: "text-center" }

import { computed, onMounted, onUnmounted, ref } from "vue";
import MobileTokenSaleCard from "@/components/Tokens/TokenSaleCard/MobileTokenSaleCard.vue";
import TokenSaleCard from "@/components/Tokens/TokenSaleCard/TokenSaleCard.vue";
import WalletConnectCard from "@/components/WalletConnect/WalletConnectCard.vue";
import LatestTransactionsCarousel from "@/components/LatestTransactionsCarousel.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { TokensService } from "@/api/generated";
import { useInfiniteQuery } from "@tanstack/vue-query";
import WebSocketClient from "@/libs/WebSocketClient";
import { useNetwork } from "@/composables/useNetwork";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const { smAndDown, mdAndDown } = useDisplay();
const { activeNetworkId } = useNetwork();

// Filters
const search = ref<string>();
const sortMode = ref<string>("market_cap");
const sortDirection = ref<string>("DESC");
const sortOptions = [
  {
    title: "Name ↓",
    value: "name",
  },
  {
    title: "Name ↑",
    value: "name",
  },
  {
    title: "Creation Date ↓",
    value: "created_at",
  },
  {
    title: "Creation Date ↑",
    value: "created_at",
  },
  {
    title: "Market Cap ↓",
    value: "market_cap",
  },
  {
    title: "Market Cap ↑",
    value: "market_cap",
  },
];
const activeSortOption = computed(() => {
  return sortOptions.find((option) => option.value === sortMode.value);
});
const sortDirectionOptions = [
  {
    title: "Ascending",
    value: "ASC",
  },
  {
    title: "Descending",
    value: "DESC",
  },
];
const scrollComponent = ref<HTMLElement>();

const { data, isFetching, fetchNextPage, hasNextPage, refetch } =
  useInfiniteQuery({
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      TokensService.listAll({
        orderBy: sortMode.value as any,
        orderDirection: sortDirection.value as any,
        search: search.value,
        limit: 50,
        page: pageParam,
      }),
    getNextPageParam: (lastPage: any, allPages, lastPageParam) => {
      if (lastPage?.meta?.currentPage === lastPage?.meta?.totalPages) {
        return undefined;
      }

      return lastPageParam + 1;
    },
    queryKey: [
      "TokensService.listAll",
      sortMode,
      sortDirection,
      search,
      activeNetworkId,
    ],
  });
const newTokenListenerSubscription = ref();
const refetchTimeout = ref<any>();
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  newTokenListenerSubscription.value = WebSocketClient.subscribeToNewTokenSales(
    (token: any) => {
      console.log("New token sale::", token);
      clearTimeout(refetchTimeout.value);
      refetchTimeout.value = setTimeout(() => {
        refetch();
      }, 4000);
    }
  );
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  newTokenListenerSubscription.value?.();
});
const handleScroll = () => {
  let element: any = scrollComponent.value;
  if (
    element.getBoundingClientRect &&
    element.getBoundingClientRect().bottom < window.innerHeight
  ) {
    if (hasNextPage.value) {
      fetchNextPage();
    }
  }
};

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock("div", {
    class: "container",
    ref_key: "scrollComponent",
    ref: scrollComponent
  }, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: "12",
          class: "py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(WalletConnectCard)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "12",
          class: "py-0"
        }, {
          default: _withCtx(() => [
            _createVNode(LatestTransactionsCarousel)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "12",
          class: "py-1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "mt-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12",
                  lg: "3",
                  class: "py-1"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createElementVNode("div", { class: "text-h4 pb-2" }, [
                      _createElementVNode("svg", {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "32",
                        height: "33",
                        viewBox: "0 0 32 33",
                        fill: "none"
                      }, [
                        _createElementVNode("rect", {
                          x: "4.92322",
                          y: "28.0369",
                          width: "22.1538",
                          height: "2.46154",
                          fill: "#812D31"
                        }),
                        _createElementVNode("rect", {
                          x: "2.46143",
                          y: "25.5754",
                          width: "27.0769",
                          height: "2.46154",
                          fill: "#812D31"
                        }),
                        _createElementVNode("rect", {
                          x: "2.46143",
                          y: "5.88306",
                          width: "27.0769",
                          height: "2.46154",
                          fill: "#812D31"
                        }),
                        _createElementVNode("rect", {
                          x: "4.92322",
                          y: "3.42154",
                          width: "22.1538",
                          height: "2.46154",
                          fill: "#812D31"
                        }),
                        _createElementVNode("rect", {
                          y: "8.34464",
                          width: "32",
                          height: "17.2308",
                          fill: "#812D31"
                        }),
                        _createElementVNode("rect", {
                          x: "7.38464",
                          y: "3.42154",
                          width: "2.46154",
                          height: "27.0769",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          x: "7.38464",
                          y: "5.88306",
                          width: "17.2308",
                          height: "22.1538",
                          fill: "#E18A24"
                        }),
                        _createElementVNode("rect", {
                          x: "4.92322",
                          y: "5.88306",
                          width: "2.46154",
                          height: "22.1538",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          x: "4.92322",
                          y: "10.8062",
                          width: "2.46154",
                          height: "14.7692",
                          fill: "#F1CD46"
                        }),
                        _createElementVNode("rect", {
                          x: "27.0768",
                          y: "8.34464",
                          width: "2.46154",
                          height: "17.2308",
                          fill: "#F1CD46"
                        }),
                        _createElementVNode("rect", {
                          x: "24.6154",
                          y: "5.88306",
                          width: "2.46154",
                          height: "22.1538",
                          fill: "#F1CD46"
                        }),
                        _createElementVNode("rect", {
                          x: "24.6154",
                          y: "8.34464",
                          width: "2.46154",
                          height: "17.2308",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          opacity: "0.4",
                          x: "22.1538",
                          y: "10.8062",
                          width: "2.46154",
                          height: "17.2308",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          opacity: "0.4",
                          x: "19.6923",
                          y: "13.2677",
                          width: "2.46154",
                          height: "17.2308",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          opacity: "0.4",
                          x: "17.2308",
                          y: "15.7292",
                          width: "2.46154",
                          height: "14.7692",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          opacity: "0.4",
                          x: "14.7692",
                          y: "18.1907",
                          width: "2.46154",
                          height: "12.3077",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          opacity: "0.4",
                          x: "12.3077",
                          y: "20.6523",
                          width: "2.46154",
                          height: "9.84615",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          opacity: "0.4",
                          x: "9.84619",
                          y: "23.1138",
                          width: "2.46154",
                          height: "7.38461",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          x: "7.38464",
                          y: "25.5754",
                          width: "2.46154",
                          height: "4.92308",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          x: "22.1538",
                          y: "25.5754",
                          width: "2.46154",
                          height: "2.46154",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          x: "7.38464",
                          y: "25.5754",
                          width: "2.46154",
                          height: "2.46154",
                          fill: "#F1CD46"
                        }),
                        _createElementVNode("rect", {
                          x: "22.1538",
                          y: "5.88306",
                          width: "2.46154",
                          height: "2.46154",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          x: "2.46143",
                          y: "8.34464",
                          width: "2.46154",
                          height: "17.2308",
                          fill: "#BD614D"
                        }),
                        _createElementVNode("rect", {
                          x: "12.3077",
                          y: "3.42154",
                          width: "12.3077",
                          height: "2.46154",
                          fill: "#F1CD46"
                        }),
                        _createElementVNode("rect", {
                          x: "9.84619",
                          y: "28.0369",
                          width: "14.7692",
                          height: "2.46154",
                          fill: "#F1CD46"
                        }),
                        _createElementVNode("rect", {
                          x: "4.92322",
                          y: "8.34464",
                          width: "2.46154",
                          height: "2.46154",
                          fill: "white"
                        }),
                        _createElementVNode("rect", {
                          x: "7.38464",
                          y: "5.88306",
                          width: "2.46154",
                          height: "2.46154",
                          fill: "white"
                        }),
                        _createElementVNode("rect", {
                          x: "9.84619",
                          y: "3.42154",
                          width: "2.46154",
                          height: "2.46154",
                          fill: "white"
                        }),
                        _createElementVNode("rect", {
                          x: "7.38464",
                          y: "0.959991",
                          width: "17.2308",
                          height: "2.46154",
                          fill: "#812D31"
                        }),
                        _createElementVNode("rect", {
                          x: "7.38464",
                          y: "30.4984",
                          width: "17.2308",
                          height: "2.46154",
                          fill: "#812D31"
                        })
                      ]),
                      _createElementVNode("div", null, "Token List")
                    ], -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "3",
                  lg: "2",
                  class: "py-1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_menu, null, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps({ variant: "outlined" }, props, {
                          block: "",
                          height: "42px"
                        }), {
                          default: _withCtx(() => [
                            _createTextVNode(" Sort: " + _toDisplayString(activeSortOption.value?.title) + " ", 1),
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode("mdi-chevron-down")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sortOptions, (item, index) => {
                              return _createVNode(_component_v_list_item, {
                                key: index,
                                onClick: ($event: any) => (sortMode.value = item.value)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item_title, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.title), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            }), 64))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "3",
                  lg: "2",
                  class: "py-1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_menu, null, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps({ variant: "outlined" }, props, {
                          block: "",
                          height: "42px"
                        }), {
                          default: _withCtx(() => [
                            _createTextVNode(" Order: " + _toDisplayString(sortDirection.value) + " ", 1),
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode("mdi-chevron-down")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sortDirectionOptions, (item, index) => {
                              return _createVNode(_component_v_list_item, {
                                key: index,
                                onClick: ($event: any) => (sortDirection.value = item.value)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item_title, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.title), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            }), 64))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "6",
                  lg: "5",
                  class: "py-1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
                      label: "Search for token",
                      variant: "outlined",
                      "append-inner-icon": "mdi-magnify",
                      density: "compact",
                      "hide-details": "",
                      "single-line": ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "12",
          class: "mt-2"
        }, {
          default: _withCtx(() => [
            (_unref(isFetching))
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_v_progress_circular, {
                      indeterminate: "",
                      color: "primary",
                      size: "80"
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                (
              (!_unref(data)?.pages?.length || !_unref(data)?.pages[0].items.length) &&
              !_unref(isFetching)
            )
                  ? (_openBlock(), _createBlock(_component_v_alert, {
                      key: 0,
                      type: "info",
                      outlined: "",
                      class: "ma-3"
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" No token sales found ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_unref(smAndDown))
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 1,
                      cols: "12",
                      md: "12",
                      class: "pb-0 pt-8"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, { class: "table-title" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "6",
                              md: "5",
                              lg: "3",
                              xl: "4"
                            }, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createElementVNode("div", { class: "d-flex" }, [
                                  _createElementVNode("div", { style: {"width":"87px"} }, "Rank"),
                                  _createElementVNode("div", null, "Token Name")
                                ], -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "1",
                              md: "2",
                              lg: "2",
                              class: "text-right"
                            }, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createTextVNode(" Price ")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "1",
                              md: "2",
                              lg: "2",
                              class: "text-right"
                            }, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode(" Market Cap ")
                              ])),
                              _: 1
                            }),
                            (!_unref(mdAndDown))
                              ? (_openBlock(), _createBlock(_component_v_col, {
                                  key: 0,
                                  cols: "3",
                                  md: "2",
                                  lg: "2",
                                  xl: "1",
                                  class: "px-1 text-center"
                                }, {
                                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                                    _createTextVNode(" Contract Address ")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            _createVNode(_component_v_col, {
                              cols: "1",
                              class: "px-1 text-center"
                            }, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createTextVNode(" Performance ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_unref(data)?.pages)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_unref(data).pages, (page) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (page?.items)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(page.items, (token) => {
                              return (_openBlock(), _createBlock(_component_v_col, {
                                key: token.sale_address,
                                cols: "12",
                                md: "12",
                                xl: "12",
                                class: "py-1"
                              }, {
                                default: _withCtx(() => [
                                  (_unref(smAndDown))
                                    ? (_openBlock(), _createBlock(MobileTokenSaleCard, {
                                        key: 0,
                                        token: token
                                      }, null, 8, ["token"]))
                                    : (_openBlock(), _createBlock(TokenSaleCard, {
                                        key: 1,
                                        token: token
                                      }, null, 8, ["token"]))
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          class: "text-center"
        }, {
          default: _withCtx(() => [
            (_unref(hasNextPage))
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: _unref(fetchNextPage),
                  variant: "outlined",
                  rounded: "",
                  size: "large",
                  loading: _unref(isFetching)
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Load More ")
                  ])),
                  _: 1
                }, 8, ["onClick", "loading"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 512))
}
}

})